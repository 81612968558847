import { getRequest, postRequest, downloadRequest } from "@/libs/axios";

// ------------------------邮件管理-----------------------
// 邮件记录列表
export function list(params) {
    return getRequest("/yethan/webNoticeEmail/listPage", params);
}
// 发送邮件
export function sendEmail(params) {
    return postRequest("/yethan/webNoticeEmail/sendEmail", params);
}
// 导出邮件列表
export function exportEmail(params) {
    return downloadRequest("/yethan/webNoticeEmail/exportExcel", params);
}
