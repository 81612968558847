<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  .ck-editor__editable {
    min-height: 185px;
  }

  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px;
  }

  /deep/.el-input__inner {
    height: 30px;
    line-height: 30px;
    border-color: rgb(23, 118, 210);
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  // import EmailHeader from "./email-header.vue";
  import {
    list,
    sendEmail,
    exportEmail
  } from "@/api/log/email.js";

  /**
   * 系统设置--邮件
   */
  export default {
    components: {
      Layout,
      // EmailHeader,


    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "系统管理",
            active: true
          }
        ],
        query: {
          keyword: "",
          sendType: "",
          sendFrom: "",
          begDate: "",
          endDate: "",
          pageNum: 1,
          pageSize: 20
        },
        list: [],
        emailSendType: "",
        emailSendFrom: "",
        emailContent: "",
        emailSubject: "",
        acceptUsers: [{
            xm: '小张',
            type: 'teacher',
            yhzh: '123',
            email: '544033294@qq.com'
          },
          {
            xm: '小红',
            type: 'student',
            yhzh: '456',
            email: 'zy67930084@126.com'
          },
        ],
        showmodal: false,
        editorData: "",
        // 当前页
        currentPage: 1,
        // 每页显示
        perPage: 20,
        // 总数
        total: 0
      };
    },
    created() {
      this.UEditorConfig.initialFrameHeight = 300; //定义富文本高度
    },
    mounted() {
      this.getList();
    },
    computed: {
      rows() {
        return this.list.length;
      },
    },
    methods: {
      /** 查询短信发送记录列表 */
      getList() {
        this.query.pageNum = this.currentPage
        this.query.pageSize = this.perPage
        list(this.query).then(res => {
          if (res.status) {
            this.list = res.data
            this.total = res.total
          }
        });
      },
      searchClick() { //搜索时先将分页的页码归1
        this.currentPage = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      /** 发送邮件 */
      emailSend() {
        // 校验类型和模块
        if (this.emailSendType == '' || this.emailSendFrom == '') {
          this.$message({
            type: 'error',
            message: "请选择类型和模块"
          });
          return;
        }
        // 校验收信人
        if (this.acceptUsers.length == 0) {
          this.$message({
            type: 'error',
            message: "请选择收件人"
          });
          return;
        }
        // 校验标题
        if (this.emailSubject == "") {
          this.$message({
            type: 'error',
            message: "请输入邮件标题"
          });
          return;
        }
        // 校验内容
        if (this.editorData == "") {
          this.$message({
            type: 'error',
            message: "请输入邮件内容"
          });
          return;
        }
        // 发送邮件
        var params = {
          users: this.acceptUsers,
          sendType: this.emailSendType,
          sendForm: this.emailSendFrom,
          emailSubject: this.emailSubject,
          emailContent: this.editorData
        }
        this.$confirm('是否确认发送邮件?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function() {
          return sendEmail(params);
        }).then((res) => {
          if (res.status) {
            this.$message({
              type: 'success',
              message: "发送成功"
            });
            this.showmodal = false
            this.emailSendType = ""
            this.emailSendFrom = ""
            this.editorData = ""
            this.emailSubject = ""
            this.acceptUsers = []
          }
        })
      },
      exportExcel() {
        exportEmail(this.query).then(res => {
          console.log(res)
          if (!res.data) {
            return
          }
          let url = window.URL.createObjectURL(new Blob([res.data]))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', '邮件发送记录.xlsx')

          document.body.appendChild(link)
          link.click()
        })
      },
      handleSizeChange(val) {
        this.perPage = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getList();
      },
    }
  };
</script>

<template>
  <Layout>
    <!--<EmailHeader :items="items" />-->

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 500px;">
          <div class="card-body">
            <div class="pb-3 border-dash  check-table-top">
              <div class="flexList">
                <el-select class=" mr-2 blueBorder" style="width: 10%;" placeholder="选择邮件类别" v-model="query.sendType"
                  size="small">
                  <el-option value="" label="所有类型"></el-option>
                  <el-option value="类型1" label="类型1"></el-option>
                  <el-option value="类型2" label="类型2"></el-option>
                </el-select>

                <el-select class=" mr-2 blueBorder" style="width: 10%;" placeholder="选择短信发自模块" v-model="query.sendFrom"
                  size="small">
                  <el-option value="" label="所有模块"></el-option>
                  <el-option value="模块1" label="模块1"></el-option>
                  <el-option value="模块2" label="模块2"></el-option>
                </el-select>

                <el-date-picker v-model="query.begDate" value-format="yyyy-MM-dd HH:mm:ss" class="w-10 mr-2"
                  type="datetime" size="small" placeholder="选择开始时间"> </el-date-picker>
                <el-date-picker v-model="query.endDate" value-format="yyyy-MM-dd HH:mm:ss" class="w-10 mr-2"
                  type="datetime" size="small" placeholder="选择结束时间"> </el-date-picker>

                <input placeholder="输入关键字" v-model="query.keyword" class="h30 form-control border-blue mr-2 w-15"
                  maxlength="50" />

                <button type="button" class="btn btn-info h30 flexList mr-2 " @click="searchClick()"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
                <b-button v-b-modal.send-message variant="outline-info" class="flexList mr-2 condition w-md"><i
                    class="iconfont icon-send mr-2"></i>发送邮件</b-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab" @click="exportExcel"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <!-- <div class="border-blue export-tab"><i class="iconfont icon-data mr-2"></i>dbf</div> -->
              </div>
            </div>


            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%;"> 序号</th>
                    <th style="width: 5%;">邮件类型</th>
                    <th style="width: 8%;">发送模块</th>
                    <th style="width: 10%;">邮件标题</th>
                    <th>邮件内容</th>
                    <th style="width: 7%;">邮箱 </th>
                    <th style="width: 5%;">用户 </th>
                    <th style="width: 5%;">发送状态</th>
                    <th style="width: 5%;">发送人 </th>
                    <th style="width: 12%;">发送时间</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj, index) in list" :key="index">
                    <td>{{index+1+(currentPage-1)*perPage}}</td>
                    <td>{{obj.sendType}}</td>
                    <td>{{obj.sendForm}}</td>
                    <td>{{obj.emailSubject}}</td>
                    <td>{{obj.emailContent}}</td>
                    <td>{{obj.email}}</td>
                    <td>{{obj.userName}}</td>
                    <td>{{obj.sendResult == '1' ? '成功' : '失败'}}</td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{obj.sendTime}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="currentPage" :page-size="perPage" layout="total, sizes,jumper, prev, pager, next"
                :total="total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>

    </div>
    <!-- 弹窗开始 -->
    <b-modal id="send-message" v-model="showmodal" centered title="邮件发送" size="lg" title-class="font-18" hide-footer>
      <div class="d-flex mb-2">
        <div class="message-name">类型模块</div>
        <div class="massge-val">
          <el-select class=" mr-2 blueBorder" style="width: 25%;" placeholder="选择邮件类别" v-model="emailSendType"
            size="small">
            <el-option value="" label="所有类型"></el-option>
            <el-option value="类型1" label="类型1"></el-option>
            <el-option value="类型2" label="类型2"></el-option>
          </el-select>

          <el-select class=" mr-2 blueBorder" style="width: 25%;" placeholder="选择短信发自模块" v-model="emailSendFrom"
            size="small">
            <el-option value="" label="所有模块"></el-option>
            <el-option value="模块1" label="模块1"></el-option>
            <el-option value="模块2" label="模块2"></el-option>
          </el-select>
        </div>
      </div>

      <div class="d-flex mb-3">
        <div class="message-name">邮件地址</div>
        <div style="flex:1;">
          <div class="mb-3">
            <textarea rows="3" class="form-control  line3 border-blue" placeholder="点击下方按钮进行收件人选取" readonly></textarea>
          </div>
          <b-button variant="outline-info" class="flexList mr-2 condition h30"><i
              class="iconfont icon-send mr-2"></i>点击选择用户</b-button>
        </div>
      </div>
      <div class="d-flex mb-2">

        <div class="message-name">邮件标题</div>
        <div class="massge-val">
          <input type="text" v-model="emailSubject" class="form-control border-blue w-100 h30" />
        </div>
      </div>
      <div class="d-flex mb-3">
        <div class="message-name">邮件内容</div>
        <div style="flex:1">
          <vue-ueditor-wrap v-model="editorData" :config="UEditorConfig"></vue-ueditor-wrap>
        </div>

      </div>

      <div class="text-center message-modal"><button type="button" class="btn btn-info h30  mr-2"
          @click="emailSend()">提交</button>
        <button type="button" class="btn btn-secondary h30  mr-2" @click="showmodal = false">取消</button>
      </div>
    </b-modal>

    <!-- 弹窗结束 -->
  </Layout>
</template>
